.heroComp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: auto; */
  text-align: center;
}


.imageSize{
  width: 60vw;
  height: auto;
}


.textinfoPainel {
  font-size: var(--font-H4);
  font-weight: var(--font-normal);
}

.rowComp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1.25rem;
  border-radius: 6.1875rem;
  background: #FBF8FF;
  padding: 0.5rem;
}
.TagDecoration {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 0.625rem;
  padding: 0.5rem 0.5rem;

  border-radius: 6.1875rem;
  background: #fff;
  cursor: pointer;
}

.TagDecoration:hover {
  background: #fff;
  box-shadow: 0px 7px 19px 5px rgba(17, 14, 61, 0.12);
  transition: 0.5s;
}

.TagDecoration:active {
  background: #fff;
  box-shadow: 0px 7px 19px 5px rgba(17, 14, 61, 0.12);
  transition: 0.5s;
}