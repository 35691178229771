.titleProjects:hover{
    color: var(--purple-color)
   
}



@media (width <= 1000px) {
    .arrowCurve {
      display: none;
    }

}