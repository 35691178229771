body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root{
  --green-color :#6CBF84;
  --green-color-light :rgba(108, 191, 132, 0.15);

  --error-color: #F25685;
  --error-color-light: rgba(242, 86, 133, 0.15);
  --warnning-color: #EB8155;
  --warnning-color-light: rgba(235, 129, 85, 0.15);
  --info-color: #5682F2;
  --info-color-light: rgba(86, 130, 242, 0.15); 

  --text-color:#110E3D;
  --text-color-light:#343434;

  --purple-color: #9E5AFF;


  --text-font: 'Inter', sans-serif;
  --font-H1: 2.5rem;
  --font-H2: 2rem;
  --font-H3: 1.5rem;
  --font-H4: 1.25rem;
  --font-H5: 1rem;
  
  --font-normal: 400;
  --font-light: 300;
  --font-medium: 500;
  --font-bold: 700;


  
}